import update from "immutability-helper";
import { useCallback, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableBox } from "./DraggableBox.js";
import { ItemTypes } from "./ItemTypes.js";
import { snapToGrid as doSnapToGrid } from "./snapToGrid.js";
import { Box, Button, List, Popup } from "devextreme-react";
import { confirm } from 'devextreme/ui/dialog';
import { useParams } from 'react-router-dom'
import { Position, ToolbarItem } from "devextreme-react/popup.js";
import DataSource from 'devextreme/data/data_source';
import { useEffect } from "react";
import { getDataStore } from "../../api/datastore.js";
import { Item } from "devextreme-react/box.js";
import axios from 'axios';
import configData from "../../config.json";
const styles = {
  width: "1500x",
  height: "750px",

  //border: "1px solid black",
  // position: "absolute"
};

export const Container = ({ snapToGrid }) => {
  let { testID } = useParams()
  const [timePuffer, settimePuffer] = useState([])
  const [sportler, setSportler] = useState([])
  const [selectedKeys, setSelectedKeys] = useState([]);

  const [sportlerPopupVisible, setsportlerPopupVisible] = useState(false)
  const token = localStorage.getItem('token');
  const [boxes, setBoxes] = useState({


  });
  function setTimePufferChange(times) {

    settimePuffer(times);
  }
  async function senddaten(data){
    const response = await axios.post(configData.SERVER_URL_T + 'Test/Auswertung_Test', data, {
        headers:{
            Authorization: "Basic " + token,
          
        }
      } )
}
  function start_welle(welle) {
    var time = new Date().getTime();
    Object.keys(boxes).map((key) => {
      if (boxes[key].top == ((welle - 1) * 150)) {
        setBoxStart(key, time);
      }
    })
  }
  const moveBox = useCallback(
    (id, left, top, id2, left2, top2) => {

      if (id2 != undefined) {
        setBoxes(
          update(boxes, {
            [id]: {
              $merge: { left, top }
            }, [id2]: {
              $merge: { left: left2, top: top2 }
            }
          })
        );
      } else {
        setBoxes(
          update(boxes, {
            [id]: {
              $merge: { left, top }
            }
          })
        );
      }
    },
    [boxes]
  );
  const setBoxStart = useCallback(
    (id, timeStart) => {


      setBoxes(
        update(boxes, {
          [id]: {
            $merge: { timeStart }
          }
        })
      );

    },
    [boxes]
  );


  const [, drop] = useDrop(
    () => ({
      accept: ItemTypes.BOX,
      drop(item, monitor) {
        const delta = monitor.getDifferenceFromInitialOffset();
        let left = Math.round(item.left + delta.x);
        let top = Math.round(item.top + delta.y);
        if (snapToGrid) {
          [left, top] = doSnapToGrid(left, top);
        }
        var id;
        {
          Object.keys(boxes).map((key) => {
            if (boxes[key].top == top && boxes[key].left == left) {
              id = key;
            }
          })
        }
        if (id != undefined) {
          moveBox(item.id, left, top, id, item.left, item.top);
        } else {
          moveBox(item.id, left, top, undefined, undefined, undefined);
        }
        return undefined;
      }
    }),
    [moveBox]
  );

  useEffect(() => {
    var box_load = localStorage.getItem("boxes;" + testID)
    if (box_load != null) {
      setBoxes(JSON.parse(box_load))
    }
    var sportlerDataSource = new DataSource({ store: getDataStore('sportler', 'sportler_id'), filter: [["trainer", "=", 0], "and", ['trainingsgruppe', '=', 'TG Günther']], paginate: false });
    sportlerDataSource.load()
      .done(function (result) {
        setSportler(result)
      })


  }, [])

  useEffect(() => {
    localStorage.setItem("boxes;" + testID, JSON.stringify(boxes))

  }, [boxes])

  return (
    <div>
      <div style={{
        width: "100%",
        height: "100%",

      }}>


        <div style={{ height: 100 }}>
          <Box
            direction="row"
            width="100%"
            height={100}>
            <Item baseSize={100}>
              <Button text={"Neu"} icon={"plus"} width={"100px"} height={"50px"} onClick={function (e) {
                setsportlerPopupVisible(true)

              }} />


            </Item>
            <Item ratio={1}>

              <Button text={"Alles zurücksetzen"} icon={"trash"} width={"200px"} height={"50px"} onClick={function (e) {
                let result = confirm("Bist du dir sicher, dass alles zurückgesetzt werden soll?");
                if (result) {
                  Object.keys(boxes).map((key) => {

                    localStorage.removeItem("zeiten;" + testID + ";" + boxes[key].sportlerID)
                    localStorage.removeItem("main_time_start;" + testID + ";" + boxes[key].sportlerID)
                    localStorage.removeItem("boxes;" + testID)
                  })
                }

              }} />
            </Item>
            <Item ratio={1}>

              <Button text={"Syncronisieren"} icon={"sync"} width={"100px"} height={"50px"} onClick={function (e) {

                var auswertungen = [];
                var box_load = localStorage.getItem("boxes;" + testID)
                if (box_load != null) {
                  var boxes = JSON.parse(box_load)
                  Object.keys(boxes).map((key) => {

                    var zeiten = localStorage.getItem("zeiten;" + testID + ";" + boxes[key].sportlerID);

                    if (zeiten != null) {
                      var zeiten_array = JSON.parse(zeiten)

                      var auswertung = {};
                      auswertung.sportlerID = boxes[key].sportlerID;
                      auswertung.testID = boxes[key].testID;
                      auswertung.zeiten = zeiten_array;
                      auswertungen.push(auswertung);
                    }
                   

                  })
                  senddaten(auswertungen);

                }

              }} />
            </Item>
            <Item ratio={1}>
              <Button text={"ZEIT"} width={"100%"} height={"100px"} onClick={function (e) {
                var time = new Date().getTime();
                settimePuffer([...timePuffer, time]);
              }} />

            </Item>

          </Box>




        </div>
        <Box
          direction="row"

        >
          <Item baseSize={65} shrink={0}>

            <Button icon={"clock"} width={"50px"} text={"1"} height={"150px"} onClick={function (e) {
              start_welle(1)
            }} /><br></br>
            <Button icon={"clock"} width={"50px"} text={"2"} height={"150px"} onClick={function (e) {
              start_welle(2)
            }} /><br></br>
            <Button icon={"clock"} width={"50px"} text={"3"} height={"150px"} onClick={function (e) {
              start_welle(3)
            }} /><br></br>
            <Button icon={"clock"} width={"50px"} text={"4"} height={"150px"} onClick={function (e) {
              start_welle(4)
            }} /><br></br>
            <Button icon={"clock"} width={"50px"} text={"5"} height={"150px"} onClick={function (e) {
              start_welle(5)
            }} />




          </Item>
          <Item baseSize={1500}>

            <div ref={drop} style={styles}>
              {Object.keys(boxes).map((key) => (
                <DraggableBox key={key} timePuffer={timePuffer} onTimePufferChanged={setTimePufferChange} id={key} {...boxes[key]} />
              ))}
            </div>
          </Item>



        </Box>

        <Popup
          visible={sportlerPopupVisible}

          dragEnabled={false}
          closeOnOutsideClick={true}
          showCloseButton={true}
          showTitle={true}
          title="Sportler"
          fullScreen={true}
        >
          <Position
            at="center"
            my="center"

          />

          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: 'Übernehmen',
              disabled: false,
              onClick: function (e) {
                var box_alt = boxes;


                selectedKeys.forEach((element) => {
                  var sport_element = sportler.find(({ sportlerID }) => sportlerID === element)
                  var komplettername = sport_element.vorname + " " + sport_element.nachname
                  box_alt[element] = { top: 0, left: 0, title: komplettername, sportlerID: element, testID: testID, timeStart: 0 }

                })
                setBoxes(box_alt)
                setsportlerPopupVisible(false)

              }
            }}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={{
              text: 'Abbrechen',
              disabled: false,
              onClick: function (e) {
                setsportlerPopupVisible(false)

              }
            }}
          />
          <List height={"100%"} width={"100%"}
            selectedItemKeys={selectedKeys}
            onOptionChanged={function (e) {
              if (e.name === "selectedItemKeys") {

                if (e.value?.length != e.previousValue?.length) {
                  setSelectedKeys(e.value);
                }

              }
            }} dataSource={sportler} displayExpr={function (e) { return e.vorname + " " + e.nachname }} keyExpr={"sportlerID"} selectionMode="all" showSelectionControls={true}
          >

          </List>
        </Popup>
      </div>
    </div>

  );
};
