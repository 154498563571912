import React from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Button,
    Column,
    Editing,
    GroupPanel,
    Lookup,
    MasterDetail
} from 'devextreme-react/data-grid';
import { getDataStore } from '../../api/datastore';
import Guid from 'devextreme/core/guid';
import { useParams } from 'react-router-dom';


export default function Task() {

    const history = useHistory();
    if (localStorage.getItem('trainingsgruppe') == undefined) {
        localStorage.setItem('trainingsgruppe', 'TG Günther')
    }
    const dataSource = {
        store: getDataStore('vorlage_schwimmen', 'vorlageschwimmenGUID'), filter: ['trainingsgruppe', "=", localStorage.getItem('trainingsgruppe')]
    };

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Wasser-Einheiten-Vorlagen</h2>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}

                columnAutoWidth={true}
                onInitNewRow={function (e) {  
                    history.push("/wasser/vorlagen/bearbeiten/0");
                }}
            >
                <GroupPanel visible={false} />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true} />
                <Column
                    dataField={'von'}
                    caption={'Von'}
                    sortIndex={0}
                    sortOrder="desc"
                    format={"EEE, dd.MM.yy HH:mm"}
                />
                <Column
                    dataField={'km'}
                    caption={'Km'}
                />
                <Column
                    dataField={'kommentar'}
                    caption={'Kommentar'}
                />
                <Column type="buttons" width={110}>
                    <Button hint="Clone" icon="edit" onClick={function (e) {
                        history.push("/wasser/vorlagen/bearbeiten/" + e.row.data.vorlageschwimmenGUID.toString());
                    }} />
                    <Button hint="Clone" icon="print" onClick={function (e) {

                        // history.push("/wettkampf-wettkaempfe/" + e.row.data.wkveranstaltung_id.toString());
                    }} />

                    <Button hint="Clone" icon="remove" onClick={function (e) {

                        // history.push("/wettkampf-uebersicht/" + e.row.data.wkveranstaltung_id.toString());
                    }} />
                </Column>







            </DataGrid>
        </React.Fragment>
    )
}



