import React from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Button,
    Column,
    Editing,
    Lookup,
    MasterDetail
} from 'devextreme-react/data-grid';
import { getDataStore } from '../../../api/datastore';
import { useParams } from 'react-router-dom';
import Guid from 'devextreme/core/guid';


export default function Task() {

    const history = useHistory();
    let { id } = useParams();
    const dataSource = {
        store: getDataStore('wkwettkampf', 'wkwettkampf_id'),filter: ['wkveranstaltung_id',"=",new Guid(id)]
    
    
    };
    
    return (
        <React.Fragment>
            <h2 className={'content-block'}>Wettkampf-Wettkämpfe</h2>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}

                columnAutoWidth={true}

            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true} />

                <Column
                    dataField={'nummer'}
                    caption={'WK-Nummer'}

                />
                <Column
                    dataField={'abchnittnr'}
                    caption={'Abschnitt'}

                />
                <Column
                    dataField={'datum'}
                    caption={'Datum'}

                />
                <Column
                    dataField={'laenge'}
                    caption={'Länge'}

                >


                </Column>
                <Column
                    dataField={'schwimmart'}
                    caption={'Schwimmart'}
               
                />
                <Column
                    dataField={'geschlecht'}
                    caption={'Geschlecht'}

                />


                <Column type="buttons" width={110}>
                    <Button hint="Clone" icon="copy" onClick={function (e) {

                        history.push("/wettkampf-ergebnisse/" + e.row.data.wkwettkampf_id.toString());
                    }} />
                </Column>



            </DataGrid>
        </React.Fragment>
    )
}



