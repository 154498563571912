import React, { useEffect } from 'react';
import { Container } from './Container.js'
import { CustomDragLayer } from './CustomDragLayer.js'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { isMobile } from 'react-device-detect';
export default function Task() {


    useEffect(() => {




    }, []);



        return (
            <React.Fragment>
                <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
                    <Container snapToGrid={true} />
                    <CustomDragLayer snapToGrid={false} />
                </DndProvider>
            </React.Fragment>
        )
    
}







