import React from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Button,
    Column,
    Editing,
    GroupPanel,
    Lookup,
    MasterDetail
} from 'devextreme-react/data-grid';
import { getDataStore } from '../../api/datastore';
import Guid from 'devextreme/core/guid';
import { useParams } from 'react-router-dom';


export default function Task() {

    const history = useHistory();
    let { testID } = useParams();
    const dataSource = {
        store: getDataStore('trainingstestergebnis', 'trainingstestergebnisGUID'), filter: ["trainingstestGUID", new Guid(testID)]


    };
    const sportlerIDs = {
        store: getDataStore('sportler', 'sportlerID')
        , filter: [["gesperrt", "=", 0], ["trainer", "=", 0]]

    };

    return (
        <React.Fragment>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}
onInitNewRow={function(e){
    e.data.trainingstestGUID = new Guid(testID);
}}
                columnAutoWidth={true}

            >

                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true} />


                <Column
                    dataField={'sportlerID'}
                    caption={'Sportler'}
                    fixed={true}
                >
                    <Lookup
                        dataSource={sportlerIDs}
                        valueExpr={'sportlerID'}
                        displayExpr={function (e) { return (e.vorname + " " + e.nachname) }}
                    />

                </Column>

                <Column
                    dataField={'klasse'}
                    caption={'Klasse'}

                />
                <Column
                    dataField={'ak'}
                    caption={'AK'}

                />
              <Column dataField={'zeit1'} />
<Column dataField={'zeit2'} />
<Column dataField={'zeit3'} />
<Column dataField={'zeit4'} />
<Column dataField={'zeit5'} />
<Column dataField={'zeit6'} />
<Column dataField={'zeit7'} />
<Column dataField={'zeit8'} />
<Column dataField={'zeit9'} />
<Column dataField={'zeit10'} />
<Column dataField={'zeit11'} />
<Column dataField={'zeit12'} />
<Column dataField={'zeit13'} />
<Column dataField={'zeit14'} />
<Column dataField={'zeit15'} />
<Column dataField={'zeit16'} />
<Column dataField={'zeit17'} />
<Column dataField={'zeit18'} />
<Column dataField={'zeit19'} />
<Column dataField={'zeit20'} />
<Column dataField={'zeit21'} />
<Column dataField={'zeit22'} />
<Column dataField={'zeit23'} />
<Column dataField={'zeit24'} />
<Column dataField={'zeit25'} />
<Column dataField={'zeit26'} />
<Column dataField={'zeit27'} />
<Column dataField={'zeit28'} />
<Column dataField={'zeit29'} />
<Column dataField={'zeit30'} />
<Column dataField={'zeit31'} />
<Column dataField={'zeit32'} />
<Column dataField={'zeit33'} />
<Column dataField={'zeit34'} />
<Column dataField={'zeit35'} />
<Column dataField={'zeit36'} />
<Column dataField={'zeit37'} />
<Column dataField={'zeit38'} />
<Column dataField={'zeit39'} />
<Column dataField={'zeit40'} />
<Column dataField={'zeit41'} />
<Column dataField={'zeit42'} />
<Column dataField={'zeit43'} />
<Column dataField={'zeit44'} />
<Column dataField={'zeit45'} />
<Column dataField={'zeit46'} />
<Column dataField={'zeit47'} />
<Column dataField={'zeit48'} />
<Column dataField={'zeit49'} />
<Column dataField={'zeit50'} />

                <Column dataField={'avg'} />
                <Column dataField={'bz1'} />
                <Column dataField={'bz2'} />
                <Column dataField={'bz3'} />
                <Column dataField={'puls'} />
                <Column dataField={'laktat'} />
                <Column dataField={'hinweis'} />



            </DataGrid>
        </React.Fragment>
    )
}



