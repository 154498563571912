import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { useHistory } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import { MonitorProgramm } from '..';


export default function Task() {
    // let { id } = useParams()


    const [value, setValue] = useState('');
    const history = useHistory();
    useEffect(() => {




    }, []);



    return (
        <React.Fragment>
           
            <div style={{ height: "85%",width: "100%", position: "absolute" }}>
                <table border="0" style={{ width: "100%" }}>
                    <colgroup>
                        <col width="100%"></col>
                    </colgroup>
                    <tbody>
                    <tr>

                        

                            <MonitorProgramm />
                      


                    </tr>
                    </tbody>

                </table>

            </div>
        </React.Fragment>
    )
}






