export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home'
  },
 
  {
    text: 'Wettkampf-Notizen',
    path: '/wettkampf-notizen-veranstaltung',
    icon: 'folder'
  }
  , 
  {
    text: 'Wettkampf-Ergebnisse',
    path: '/wettkampf-veranstaltungen',
    icon: 'folder'
  },
  {
    text: 'Test',
    path: '/test/uebersicht',
    icon: 'folder'
  },

  {
    text: 'Fernseher',
    path: '/monitor/uebersicht',
    icon: 'folder'
  }
  ];
