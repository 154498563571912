import React from 'react';
import 'devextreme/data/odata/store';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { useHistory } from "react-router-dom";
import DataGrid, {
  Column,
  Sorting,
  Button,

  Editing,



  Lookup,
  MasterDetail
} from 'devextreme-react/data-grid';
import DetailTemplate from './wettkampf_notizen_details.js';
import { getDataStore } from '../../../api/datastore.js';



export default function Task() {
  let { id } = useParams()

  const history = useHistory();
  const sportlerIDs = {
    store: getDataStore('sportler', 'sportlerID')
    , filter: [["gesperrt", "=", 0], ["trainer", "=", 0]]

  };

  const dataSource = {
    store: getDataStore('wknotiz_meldung', 'wknotiz_meldung_id')
    , filter: ["wknotiz_veranstaltung_id", "=", new Guid(id)]

  };
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Wettkampfergebnisse</h2>
<div style={{height:"85%", position:"absolute"}}>


      <DataGrid
        className={'notizen'}
        dataSource={dataSource}
        showBorders={false}
        onInitNewRow={function (e) {
          e.data.wknotiz_veranstaltung_id = id;
        }}
        columnAutoWidth={true}
        height={"100%"}
      paging={{pageSize:200}}
      
      >
        <Sorting mode="multiple" /> {/* or "multiple" | "none" */}
        <Editing
          mode="batch"
          allowUpdating={true}
          allowAdding={true}
          allowDeleting={true} />


        <Column
          dataField={'laenge'}
          caption={'Länge'}
          width={90}
        >


        </Column>
        <Column
          width={150}
          dataField={'schwimmart'}
          caption={'Schwimmart'}

        ></Column>
        <Column
          dataField={'sportlerID'}

          caption={'Name'}
          hidingPriority={8}
        >
          <Lookup
            dataSource={sportlerIDs}
            valueExpr={'sportlerID'}
            displayExpr={function (e) { return (e.vorname + " " + e.nachname) }}
          />
        </Column>
        <Column dataField={'wetkkampf'} width={90} sortIndex={0} sortOrder={"asc"} caption={'WK'}
        />
        <Column
          width={100}
          dataField={'lauf'}
          caption={'Lauf'}
          sortIndex={1}
          sortOrder={"asc"}

        ></Column>
        <Column
          width={100}
          dataField={'bahn'}
          caption={'Bahn'}
          sortIndex={2}
          sortOrder={"asc"}
        ></Column>


        <Column type="buttons">
          <Button icon="find" onClick={function (e) {

            history.push("/wettkampf-notizen-auswertung/" + e.row.data.wknotiz_meldung_id.toString());
          }} />
        </Column>

        <MasterDetail
          enabled={true}
          component={DetailTemplate}
        />

      </DataGrid>
      </div>
    </React.Fragment>
  )
}






