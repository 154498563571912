import React from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Button,
    Column,
    Editing,
    GroupPanel,
    Lookup,
    MasterDetail
} from 'devextreme-react/data-grid';
import { getDataStore } from '../../api/datastore';
import Guid from 'devextreme/core/guid';
import { useParams } from 'react-router-dom';


export default function Task() {

    const history = useHistory();
    let { testID } = useParams();
    const dataSource = {
        store: getDataStore('trainingstest', 'trainingstestGUID')


    };


    return (
        <React.Fragment>
            <h2 className={'content-block'}>StandardTests</h2>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}

                columnAutoWidth={true}

            >
                <GroupPanel visible={true} />
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={false} />
                <Column
                    dataField={'datum'}
                    caption={'Datum'}

                />
                <Column
                    dataField={'ort'}
                    caption={'Ort'}

                />
                <Column
                    dataField={'bahn'}
                    caption={'Bahnlänge'}

                />
                <Column
                    dataField={'bezeichnung'}
                    caption={'Bezeichnung'}

                />
                <Column
                    dataField={'trainingsgruppe'}
                    caption={'Trainingsgruppe'}

                />
                <Column
                    dataField={'abgang'}
                    caption={'Abgang'}

                />
                <Column
                    dataField={'hinweise'}
                    caption={'Hinweise'}

                />
                <Column type="buttons" width={110}>
                <Button  icon="clock" onClick={function (e) {

history.push("/test/stopwatch/" + e.row.data.trainingstestGUID.toString());
}} />
   <Button  icon="fields" onClick={function (e) {

history.push("/test/zeiten/" + e.row.data.trainingstestGUID.toString());
}} />
   <Button  icon="chart" onClick={function (e) {

history.push("/test/auswertung/" + e.row.data.trainingstestGUID.toString());
}} />
                  
                </Column>







            </DataGrid>
        </React.Fragment>
    )
}



