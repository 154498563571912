import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { useHistory } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import DataSource from 'devextreme/data/data_source';
import { getDataStore } from '../../api/datastore';
import { DataGrid } from 'devextreme-react';
import { Column, Editing, EditingTexts } from 'devextreme-react/data-grid';

Date.prototype.addHours = function (h) {
    this.setHours(this.getHours() + h);
    return this;
};
export default function Programm() {
    // let { id } = useParams()


    const [value, setValue] = useState('');
    const [dataBZ, setDataBZ] = useState([]);
    const [monitoring, setMonitoring] = useState([]);
    const [title, setTitle] = useState('');
    const history = useHistory();
    useEffect(() => {
        const dataSource = new DataSource({
            store: getDataStore('vorlage_schwimmen', 'vorlage_schwimmen_id')
            ,
            filter: [['von', '>=', new Date().addHours(-3)], ['bis', '<=', new Date().addHours(5)], ['trainingsgruppe', '=', 'TG Günther']]
        });

        dataSource.load().done(function (data) {
            if (data.length > 0) {
                setValue(JSON.parse(data[0].programmtext2))
                setDataBZ(data)

                var d = new Date(data[0].von),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear(), hour = '' + d.getHours(), minute = '' + d.getMinutes();

                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                if (hour.length < 2) hour = '0' + hour;
                if (minute.length < 2) minute = '0' + minute;


                var days = ["Sonnntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
                var titel = days[d.getDay()] + ", " + day + "." + month + "." + year + " " + hour + ":" + minute + " Uhr";


                if (data[0].kommentar != '') {
                    titel = titel + " : " + data[0].kommentar;
                };
                setTitle(titel);

            }

        })





        var sportler = new DataSource({ store: getDataStore('sportler', 'sportler_id'), filter: [["trainer", "=", 0], "and", ['trainingsgruppe', '=', 'TG Günther']], paginate: false });
        sportler.load()
            .done(function (result) {
                var sportler_mapping = [];
                var uebersicht = [];
                var startdatumzeit = new Date();
                var enddatumzeit = new Date();
                enddatumzeit.setDate(enddatumzeit.getDate() + 1);
                startdatumzeit.setDate(enddatumzeit.getDate() - 4);


                var startdatum = new Date(startdatumzeit.toDateString());
                var enddatum = new Date(enddatumzeit.toDateString());

                var i;
                for (i = 0; i < result.length; i++) {

                    sportler_mapping[result[i].sportlerID] = i;
                    uebersicht.push({ "name": result[i].verein });

                    var antworten = new DataSource({ store: getDataStore('monitoring_uebersicht_v2', 'monitorung_uebersicht_v2_id'), filter: [["datum", ">", startdatum], "and", ["datum", "<", enddatum], "and", ['sportlerID', '=', result[i].sportlerID]], paginate: false });
                    antworten.load()
                        .done(function (result2) {

                            if (result2.length > 0) {
                                console.log(uebersicht)

                                uebersicht[sportler_mapping[result2[0].sportlerID]].erholung_allgemein = result2[0].erholung_allgemein + "-" + result2[0].beanspruchung_allgemein;

                                setMonitoring(uebersicht)
                            }


                        })


                };







            });



    }, []);



    return (
        <React.Fragment>
            <td>

                <div style={{float:"left", width:"95%", position:"relative"}}>


                    <div style={{ "backgroundColor": "white", margin: "20px 200px 20px 200px", padding: "10px 20px 10px 20px", "boxShadow": "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,  rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px" }}>
                        <p>
                            <span style={{ "textDecoration": "underline", "fontSize": "20px" }}>
                                <strong>{title}</strong>
                            </span></p>
                        <ReactQuill theme="bubble" value={value} onChange={setValue} />

                        <div id="BottomGrid" style={{ position: "relative", bottom: "0" }}>
                            <DataGrid

                                dataSource={dataBZ}
                            >

                                <Editing
                                    mode="cell"
                                    allowUpdating={false}
                                    allowAdding={false}
                                    allowDeleting={false} />

                                <Column dataField="bz1" caption="BZ 1" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz2" caption="BZ 2" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz3" caption="BZ 3" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz4" caption="BZ 4" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz5" caption="BZ 5" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz6" caption="BZ 6" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz7" caption="BZ 7" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="bz8" caption="BZ 8" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />
                                <Column dataField="km" caption="∑" alignment="center" dataType="Number" format={{ type: "fixedPoint", precision: 2 }} cssClass="keepSpaces2" />


                            </DataGrid>
                        </div>
                    </div>
                </div>
                <div style={{ width: "5%", "boxShadow": "rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 2px 1px -1px", position: "absolute", top: "50%", transform: "translateY(-50%)", right: "0%" }}>
                    <DataGrid

                        dataSource={monitoring}
                        showColumnHeaders={false}
                    >

                        <Editing
                            mode="cell"
                            allowUpdating={false}
                            allowAdding={false}
                            allowDeleting={false} />

                        <Column dataField="name" cssClass="monitoring_kleinfenster" />
                        <Column dataField="erholung_allgemein" cssClass="monitoring_kleinfenster" />


                    </DataGrid>

                </div>

            </td>


        </React.Fragment>
    )
}






