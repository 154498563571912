import axios from 'axios';
import defaultUser from '../utils/default-user';
import configData from "../config.json";
import { Buffer } from 'buffer';

export async function signIn(email, password) {
  try {
    var data = { Username: email, Password: password }
    try {
      const response = await axios.post(configData.SERVER_URL + 'Users/Authenticate', data)
      localStorage.setItem('token', Buffer(email + ':' + password).toString('base64'))

      localStorage.setItem('user', JSON.stringify(response.data))
      return {
        isOk: true,
        data: response.data
      };
    } catch (err) {

      return {
        isOk: false,
        data: err.message
      };
    }


  }
  catch {
    return {
      isOk: false,
      message: "Authentication failed"
    };
  }
}

export async function getUser() {
  try {
    // Send request

    try {

      var buf = localStorage.getItem('token')
      if (buf != undefined) {
        var bufdec = Buffer.from(buf, 'base64').toString('ascii');
        var data = { Username: bufdec.split(':')[0], Password: bufdec.split(':')[1] }
        const response = await axios.post(configData.SERVER_URL + 'Users/Authenticate', data)
  
        localStorage.setItem('user', JSON.stringify(response.data))
        return {
          isOk: true,
          data: response.data
        };
      }
      return {
        isOk: false
      };

    } catch (err) {

      return {
        isOk: false
      };
    }
  }
  catch {
    return {
      isOk: false
    };
  }
}

export async function createAccount(email, password) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to create account"
    };
  }
}

export async function changePassword(email, recoveryCode) {
  try {
    // Send request
    console.log(email, recoveryCode);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to change password"
    }
  }
}

export async function resetPassword(email) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true
    };
  }
  catch {
    return {
      isOk: false,
      message: "Failed to reset password"
    };
  }
}
