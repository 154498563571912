import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { useHistory } from "react-router-dom";
import DataGrid, {
  Column,
  Scrolling,
  Editing,
  Lookup,
  MasterDetail,
  Toolbar,
  Item as Toolit
} from 'devextreme-react/data-grid';
import Box, {
  Item,
} from 'devextreme-react/box';
import DetailTemplate from './wettkampf_notizen_details.js';
import { getDataStore } from '../../../api/datastore.js';
import axios from 'axios';
import configData from "../../../config.json";
import Chart, { Series } from 'devextreme-react/chart';
import { Button } from 'devextreme-react';
export default function Task() {
  let { id } = useParams()

  const history = useHistory();
  const [formData, setformData] = useState([])
  const [sportler, setsportler] = useState([])
  const aktuellezeitenref = useRef(null);
  const kaderref = useRef(null);
  const historieref = useRef(null);
  const verlaufref = useRef(null);

  const dataSource = {
    store: getDataStore('wknotiz_meldung', 'wknotiz_meldung_id')
    , filter: ["wknotiz_veranstaltung_id", "=", new Guid(id)]

  };
  useEffect(() => {

    var auth = localStorage.getItem('token')
    axios.get(configData.SERVER_URL + 'Zeiten/getAuswertung?meldung=' + id, { headers: { 'Authorization': 'Basic ' + localStorage.getItem('token') } })
      .catch(err => {
        debugger;
        console.log(err)
      })
      .then(response => {
        setformData(response.data.result_set)
        console.log(response.data.result_set)


      });




  }, []);
  return (
    <React.Fragment>
        <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                <h2 className={'content-block'}>{formData.sportlername} : {formData.laenge} {formData.schwimmart} </h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>
                    <Button
                       
                        icon="refresh"
                       
                        onClick={function(e){
                          var auth = localStorage.getItem('token')
                          axios.get(configData.SERVER_URL + 'Zeiten/getAuswertung?meldung=' + id, { headers: { 'Authorization': 'Basic ' + localStorage.getItem('token') } })
                            .catch(err => {
                              debugger;
                              console.log(err)
                            })
                            .then(response => {
                              setformData(response.data.result_set)
                              console.log(response.data.result_set)
                      
                      
                            });
                        }}
                    />
                </div>
            </div>
    

     
          <Box
            direction="row"

          >
            <Item ratio={2}>
              <div style={{ "paddingTop": 0 }} className={'content-block dx-card responsive-paddings'} >
                <p><b>{formData.veranstaltung}</b></p>
                <DataGrid
ref={aktuellezeitenref}
                  dataSource={formData.aktuellezeiten}
                  showBorders={true}

                  showColumnHeaders={true}
                  allowColumnReordering={false}
                  allowColumnResizing={false}
                // columnAutoWidth={true}

                >

                  <Editing
                    mode="cell"
                    allowUpdating={false}
                    allowAdding={false}
                    allowDeleting={false} />

                  <Column dataField="zeit" />
                  <Column dataField="rundenzeit" />
                  <Column dataField="frequenz" />
                  <Column dataField="bemerkung" />

                </DataGrid>
              </div>
              <div style={{ "paddingTop": 0 }} className={'content-block dx-card responsive-paddings'}  >
                <p><b>Best-/Kaderzeiten</b></p>
                <DataGrid
ref={kaderref}
                  dataSource={formData.kaderbestzeiten}
                  showBorders={true}
                  showColumnHeaders={false}
                  allowColumnReordering={false}
                  allowColumnResizing={false}
                
                >

                  <Scrolling mode="virtual" rowRenderingMode="virtual" />
                  <Editing
                    mode="cell"
                    allowUpdating={false}
                    allowAdding={false}
                    allowDeleting={false} />
                  <Column dataField="pos" visible={false} sortOrder={0} />
                  <Column dataField="bezeichnung"/>
                  <Column dataField="zeit"  />
                  <Column dataField="ort"  />
                  <Column dataField="datum" dataType={"datetime"} format={"dd.MM.yyyy"}/>
              

                </DataGrid>
              </div>
              <div className={'content-block dx-card responsive-paddings'} style={{ "paddingTop": 0 }} >
                <p><b>Verlauf</b></p>
                <Chart ref={verlaufref} id="chart" dataSource={formData.geschwommenezeiten} argumentAxis={{ argumentType: "datetime" }}>
                  <Series
                    valueField="zeit_ms"
                    arg
                    argumentField="datum"
                    name="Zeiten"
                    type="spline"
                    color="#ffaa66" />
                </Chart>
              </div>
            </Item>
            <Item ratio={1}>
              <div style={{ "marginLeft": 0, "paddingTop": 0 }} className={'content-block dx-card responsive-paddings'}>

                <p><b>Historie</b></p>
                <DataGrid
ref={historieref}
                  dataSource={formData.geschwommenezeiten}
                  showBorders={true}
                  showColumnHeaders={false}
                  allowColumnReordering={false}
                  allowColumnResizing={false}
                  onRowPrepared={function(e){
                    if(e.rowType === "data") {
                      if (e.data.mark == true){
                        e.rowElement.style.backgroundColor = "#ff5722";
                      }
                     
                  }
                  }}
                  height={"800"}
                >
        
                  <Scrolling mode="virtual" rowRenderingMode="virtual" />
                  <Editing
                    mode="cell"
                    allowUpdating={false}
                    allowAdding={false}
                    allowDeleting={false} />
                      <Column dataField="zeit"  />  
                      <Column dataField="zeit_ms" sortIndex={0} sortOrder={"asc"} visible={false}  />

                       <Column dataField="datum" dataType={"datetime"} format={"dd.MM.yyyy"}  />
                  <Column dataField="ort"  />
                  <Column dataField="bahn"  visible={false}/>
                  <Column dataField="mark" visible={false} />
               
                


                </DataGrid>
              </div>
            </Item>

          </Box>
       






    </React.Fragment>
  )
}






