import React from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
  Button,
  Column,
  
 
  
  Editing,
  
 
  
  Lookup,
  MasterDetail
} from 'devextreme-react/data-grid';
import { getDataStore } from '../../../api/datastore';


export default function Task() {
  
  const history = useHistory();

  return (
    <React.Fragment>
      <h2 className={'content-block'}>Wettkämpfe</h2>

      <DataGrid
        className={'dx-card wide-card'}
        dataSource={dataSource}
        showBorders={false}
      
        columnAutoWidth={true}
      
      >
         <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true} />
     
      
     <Column
          dataField={'von'}
          caption={'Von'}
          dataType={"date"}
          width={90}   
        >
          
         
        </Column>
        <Column
          dataField={'bis'}
          caption={'Bis'}
          dataType={"date"}
          width={90}   
        >
          
         
        </Column>
        <Column
          dataField={'bezeichnung'}
          caption={'Bezeichnung'}
    
        >
          
         
        </Column>
        <Column
          dataField={'ort'}
          caption={'Ort'}
    
        >
          
         
        </Column>
        <Column
          dataField={'bahn'}
          caption={'Bahn'}
    
        >
          
         
        </Column>
        <Column type="buttons" width={110}>
          <Button hint="Clone" icon="copy" onClick={function(e){
  
 history.push("/wettkampf-notizen/" + e.row.data.wknotiz_veranstaltung_id.toString());
          }} />
        </Column>
      
       
        
      </DataGrid>
    </React.Fragment>
)}

const dataSource = {
store: getDataStore('wknotiz_veranstaltung','wknotiz_veranstaltung_id')
  

};


