import React, { useState, useEffect, useRef } from 'react';
import 'devextreme/data/odata/store';
import { useParams } from 'react-router-dom'
import Guid from 'devextreme/core/guid';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Column,
    Scrolling,
    Editing,
    Lookup,
    MasterDetail,
    Toolbar,
    Item as Toolit,
    Item,
    Label
} from 'devextreme-react/data-grid';
import Box, {

} from 'devextreme-react/box';
import { getDataStore } from '../../api/datastore.js';
import axios from 'axios';
import configData from "../../config.json";
import Chart, { Series } from 'devextreme-react/chart';
import { Button, Form } from 'devextreme-react';
import ResponsiveBox, {
    Row,
    Col,
    Item as BoxItem,
    Location,
} from 'devextreme-react/responsive-box';
import { GroupItem } from 'devextreme-react/form.js';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

export default function Task() {

    let { id } = useParams()

    const history = useHistory();
    const [formData, setformData] = useState({})
    const [loading, setloading] = useState(true)

    const [dataProgramm, setdataProgramm] = useState()


    useEffect(() => {


        if (id == "0") {
            var datum = new Date();
            var datumbis = new Date();
            datumbis = datumbis.setTime(datum.getTime() + (2 * 60 * 60 * 1000));

            setformData({ von: datum, bis: datumbis, dauer: 2, anzeigenab: datum, trainingsgruppe: localStorage.getItem('trainingsgruppe') })
          

        } else {
            axios.get(configData.SERVER_URL + 'Zeiten/getAuswertung?meldung=' + id, { headers: { 'Authorization': 'Basic ' + localStorage.getItem('token') } })
                .catch(err => {

                    console.log(err)
                })
                .then(response => {
                    //  setformData(response.data.result_set)
                    console.log(response.data.result_set)

                });
        }
        setloading(false)


    }, []);

    if (loading) return <p></p>;

    return (
        <React.Fragment>
            <div className="dx-toolbar-items-container" style={{ height: "60px" }}>
                <div className="dx-toolbar-before">
                    <h2 className={'content-block'}>  </h2>
                </div>
                <div className="dx-toolbar-after" style={{ "paddingTop": "20px", "paddingRight": "40px" }}>

                    <Button

                        icon="save"
                        text="speichern"
                        onClick={function (e) {
                            if (id == "0") {
                                var datum = new Date();
                                var datumbis = new Date();
                                datumbis = datumbis.setTime(datum.getTime() + (2 * 60 * 60 * 1000));

                                setformData({ von: datum, bis: datumbis, dauer: 2, anzeigenab: datum, trainingsgruppe: localStorage.getItem('trainingsgruppe') })
                                setdataProgramm("hallo2")

                            } else {
                                axios.get(configData.SERVER_URL + 'Zeiten/getAuswertung?meldung=' + id, { headers: { 'Authorization': 'Basic ' + localStorage.getItem('token') } })
                                    .catch(err => {

                                        console.log(err)
                                    })
                                    .then(response => {
                                        //  setformData(response.data.result_set)
                                        console.log(response.data.result_set)

                                    });
                            }

                        }}
                    />

                    <Button
                        style={{ "marginLeft": 20 }}
                        icon="copy"
                        text="als Kopie speichern"
                        onClick={function (e) {
                            var auth = localStorage.getItem('token')
                            axios.get(configData.SERVER_URL + 'Zeiten/getAuswertung?meldung=' + id, { headers: { 'Authorization': 'Basic ' + localStorage.getItem('token') } })
                                .catch(err => {
                                    debugger;
                                    console.log(err)
                                })
                                .then(response => {
                                    setformData(response.data.result_set)
                                    console.log(response.data.result_set)


                                });
                        }}
                    />


                </div>
            </div>


            <ResponsiveBox

            >
                <Row ratio={1}></Row>
                <Row ratio={1}></Row>
                <Row ratio={1}></Row>
                <Col ratio={1}></Col>
                <Col ratio={1}></Col>
                <Col ratio={1}></Col>
                <BoxItem>
                    <Location
                        row={0}
                        col={0}
                        colspan={2}
                        rowspan={3}
                        screen="lg"
                    ></Location>
                    <Location
                        row={1}
                        col={0}
                        colspan={3}
                        rowspan={1}
                        screen="sm"
                    ></Location>
                    <div style={{ "paddingTop": 0 }} className={'content-block dx-card responsive-paddings'} >

                        <Form style={{ "paddingTop": 20 }}>
                            <GroupItem caption="Programm" />
                        </Form>
                        <ReactQuill theme="bubble" value={dataProgramm} onChange={setdataProgramm} modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons



                                //   [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown

                                [{ 'color': [] },
                                    //   { 'background': [] }
                                ],          // dropdown with defaults from theme

                                //   [{ 'align': [] }],

                                //  ['clean']                                         // remove formatting button
                            ]
                        }} placeholder="Hier ist Platz für das Programm" />
                    </div>
                </BoxItem>
                <BoxItem>
                    <Location
                        row={0}
                        col={2}
                        colspan={1}
                        rowspan={1}
                        screen="lg"
                    ></Location>
                    <Location
                        row={0}
                        col={0}
                        colspan={3}
                        rowspan={1}
                        screen="sm"
                    ></Location>

                    <div style={{ "paddingTop": 0 }} className={'content-block dx-card responsive-paddings'}>
                        <Form formData={formData} id={'form'} style={{ "paddingTop": 20 }} labelLocation="left">
                            <GroupItem caption="Details" >
                                <Item dataField="von" editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                                    <Label text="Beginn" />
                                </Item>
                                <Item dataField="bis" editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                                    <Label text="Ende" />
                                </Item>
                                <Item dataField="dauer" editorType="dxNumberBox" editorOptions={{}}>
                                    <Label text="Beginn" />
                                </Item>
                                <Item dataField="kommentar" editorOptions={{}}>
                                    <Label text="Kommentar" />
                                </Item>
                                <Item dataField="hinweise" editorOptions={{}}>
                                    <Label text="Hinweise" />
                                </Item>
                                <Item dataField="anzeigenab" editorType="dxDateBox" editorOptions={{ type: "datetime" }}>
                                    <Label text="Sichtbar ab" />
                                </Item>

                            </GroupItem>

                        </Form>

                    </div>

                </BoxItem>
                <BoxItem>
                    <Location
                        row={1}
                        col={2}
                        colspan={1}
                        rowspan={1}
                        screen="lg"
                    ></Location>
                    <Location
                        row={2}
                        col={0}
                        colspan={3}
                        rowspan={1}
                        screen="sm"
                    ></Location>
                    <div style={{ "paddingTop": 0 }} className={'content-block dx-card responsive-paddings'}>

                        <Form style={{ "paddingTop": 20 }}>
                            <GroupItem caption="Summen" />
                        </Form>
                    </div>
                </BoxItem>



            </ResponsiveBox>







        </React.Fragment>
    )
}






