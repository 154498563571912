import React, { useRef } from 'react';
import { DataGrid, Column, Editing, KeyboardNavigation, Sorting, Toolbar, Item, Scrolling } from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import ODataStore from 'devextreme/data/odata/store';
import { getDataStore } from '../../../api/datastore';




export default function App(props) {
    const datagridref = useRef(null);

    const dataSource = new DataSource({
        store: getDataStore('wknotiz_zeiten', 'wknotiz_zeiten_id')
        ,
        filter: ['wknotiz_meldung_id', '=', props.data.data.wknotiz_meldung_id], sort: ['zeit']
    });
    const dataSource_erg = new DataSource({
        store: getDataStore('wkergebnis_view', 'wkergebnis_id')
        ,
        filter: [['sportlerID', '=', props.data.data.sportlerID], ['laenge', '=', props.data.data.laenge], ['schwimmart', '=', props.data.data.schwimmart]],
    });
    return (
        <React.Fragment>


            <DataGrid
                className='details'
                ref={datagridref}
                dataSource={dataSource}
                showBorders={true}
                showColumnHeaders={true}
                allowColumnReordering={false}
                allowColumnResizing={false}
                // columnAutoWidth={true}
                selectTextOnEditStart={true}
                onInitNewRow={function (e) {
                    e.data.wknotiz_meldung_id = props.data.data.wknotiz_meldung_id;
                }}
            >
                <Toolbar>
                <Item name="addRowButton" />
                <Item name="saveButton" />
                <Item name="revertButton" />
                    <Item
                        name="TEST"

                        location="before"

                        widget="dxButton"
                        options={{
                            icon: 'refresh',
                            disabled: false,
                            onClick: function (e) {
                                datagridref.current.instance.refresh();
                            }
                        }}
                    />
                </Toolbar>
                <Sorting mode="none" />
                <KeyboardNavigation
                    editOnKeyPress={true}
                    enterKeyAction={"startEdit"}
                    enterKeyDirection={"column"} />
                <Editing
                    mode="batch"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={false} />

                <Column dataField="zeit" setCellValue={function (newData, value, currentRowData) {


                    newData.zeit = value;
                    try {
                        var t = value;
                        var min = Number(t.split(':')[0]) * 60 * 1000
                        var sec = Number(t.split(':')[1].split(",")[0]) * 1000;
                        var hunsec = Number(t.split(':')[1].split(",")[1]) * 10;
                        newData.zeit_ms = min + sec + hunsec;
                    } catch {

                    }
                    var index = datagridref.current.instance.getRowIndexByKey(currentRowData.wknotiz_zeiten_id)

                    if (index != 0 && value != ";,") {
                        var rows = datagridref.current.instance.getVisibleRows()

                        var t = rows[index - 1]?.data.zeit_ms;
                        newData.rundenzeit_ms = newData.zeit_ms - t;
                        var s = newData.rundenzeit_ms;
                        var ms = s % 1000;
                        s = (s - ms) / 1000;
                        var secs = s % 60;
                        s = (s - secs) / 60;
                        var mins = s % 60;
                        var hrs = (s - mins) / 60;

                        function pad(num, size) {
                            num = num.toString();
                            while (num.length < size) num = "0" + num;
                            return num;
                        }

                        newData.rundenzeit = pad(mins, 2) + ':' + pad(secs, 2) + ',' + pad(ms / 10, 2);


                    } else {
                        newData.rundenzeit_ms = newData.zeit_ms;
                        newData.rundenzeit = value;
                    }


                }} width={"150"} editorOptions={{
                    mask: "90:00,00",
                    mode: "tel",
                    useMaskedValue: true,
                    showMaskMode: 'onFocus'
                }} />
                <Column dataField="rundenzeit" width={"150"} editorOptions={{ readOnly: true }} />
                <Column dataField="frequenz" width={"150"} editorOptions={{

                    mode: "tel",

                }} />
                <Column dataField="bemerkung" />

            </DataGrid>


        </React.Fragment>
    );
}

