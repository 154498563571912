import { Button, List } from 'devextreme-react'
import { memo, useState, useEffect } from 'react'
import {
  Box as ItemBox,
  Item,
} from 'devextreme-react/box';
import './box.css'
const styles = {
  border: '1px dashed gray',
backgroundColor: 'white',
  cursor: 'move',
  height: '150px',
  width: '300px'

}

let id_zeiten = 0;
export const Box = memo(function Box({ title, yellow, preview, sportlerID, testID, timePuffer , onTimePufferChanged, timeStart}) {
  const [titleneu, settitleneu] = useState(sportlerID + title)
  const [main_time, setmain_time] = useState("00:00,00")
  const [rest_time, setrest_time] = useState("00:00,00")
  const [last_time, setlast_time] = useState(0)
  const [main_time_start, setmain_time_start] = useState(0)
  const [calc_times, setcalc_times] = useState({ min: 0, min_text: "00:00,00", max: 0, max_text: "00:00,00", avg: 0, avg_text: "00:00,00" })
  const [zeiten, setzeiten] = useState([])
  
  const [isTimePuffer, setisTimePuffer] = useState(false)
  let running_time;

  function msToMS0(ms) {
    let miliseconds = ms / 100;
    const minutes = parseInt(miliseconds / 600);
    miliseconds = miliseconds % 600;
    const seconds = parseInt(miliseconds / 10);
    miliseconds = Math.floor((miliseconds % 10));
    return (String(minutes).padStart(2, '0') + ":" + String(seconds).padStart(2, '0') + "," + miliseconds);
  }
  function zwischenzeit(time) {
    var zeitstring = msToMS0(time - main_time_start);
    setzeiten([{ id: id_zeiten++, zeit: zeitstring, start: main_time_start, timestamp: time, zeit_ms: (time - main_time_start) }, ...zeiten])
    setlast_time(time);


  }
  function calc_times_berechnen(zeiten_array) {
    if (zeiten_array.length == 0){
      setcalc_times({ min:0, min_text:"", max:0, max_text:"", avg:0, avg_text:"" })
    }else{
    var min = 0;
    var min_text = "00:00,00";
    var max = 0;
    var max_text = "00:00,00";
    var avg = 0;
    var avg_text = "00:00,00";

    var starts = [];

    zeiten_array.forEach((element) => {

      const result = starts.find(({ start }) => start == element.start);

      if (result == null) {
        var start = { start: element.start, zeit: element.zeit, zeit_ms: element.zeit_ms, timestamp: element.timestamp };
        starts.push(start)
      } else {
        if (result.timestamp < element.timestamp) {
          var objIndex = starts.findIndex((obj => obj.start == element.start));
          starts[objIndex].zeit = element.zeit
          starts[objIndex].zeit_ms = element.zeit_ms
          starts[objIndex].timestamp = element.timestamp

        }
      }


    }

    );

    starts.forEach((element) => {

      if (element.zeit_ms < min || min == 0) {
        min = element.zeit_ms;
        min_text = element.zeit;
      }
      if (element.zeit_ms > max) {
        max = element.zeit_ms;
        max_text = element.zeit;
      }
      avg += element.zeit_ms;
    })
    avg = avg / starts.length;
    if (avg != 0) {
      avg_text = msToMS0(avg);
    }


  setcalc_times({ min, min_text, max, max_text, avg, avg_text })
}
  

  }
  useEffect(() => {

    if (localStorage.getItem("zeiten;" + testID + ";" + sportlerID) != undefined) {
      setzeiten(JSON.parse(localStorage.getItem("zeiten;" + testID + ";" + sportlerID)))
    }
    if (localStorage.getItem("main_time_start;" + testID + ";" + sportlerID) != undefined) {
      setmain_time_start(localStorage.getItem("main_time_start;" + testID + ";" + sportlerID))
      setmain_time("00:00,0")
    }
  }, [sportlerID])
  useEffect(() => {
  
    if (timePuffer.length > 0) {
      setisTimePuffer(true)
    } else {
      setisTimePuffer(false)
    }
  }, [timePuffer])
  useEffect(() => {
  
    if (timeStart != 0) {
      setmain_time_start(timeStart)
      setmain_time("00:00,0")
      setrest_time("")
    } 
  }, [timeStart])
  useEffect(() => {
   if (!preview){
    localStorage.setItem("zeiten;" + testID + ";" + sportlerID, JSON.stringify(zeiten))
    calc_times_berechnen(zeiten)
   }
   
  }, [zeiten])
  useEffect(() => {
   
    if (main_time_start != 0 && !preview) {
      localStorage.setItem("main_time_start;" + testID + ";" + sportlerID, main_time_start)
     
  
    }
  }, [main_time_start])


  useEffect(
    function running_time() {
      const interval = setInterval(() => {
        if (main_time_start != 0) {

          var time = new Date().getTime();
          var anzeige = msToMS0(time - main_time_start)
          setmain_time(anzeige);
          if (last_time > main_time_start) {
            var rest_anzeige = msToMS0(time - last_time)
            setrest_time(rest_anzeige)
          }

        }
      }, 100)
      return () => clearInterval(interval)
    },
    [main_time],
  )
  return (
    <div>

      <div
        style={{ ...styles }}
        role={preview ? 'BoxPreview' : 'Box'}



      >

        <ItemBox
          direction="col"
          width="100%"

          height="100%">
          <Item ratio={3}>
            <div>
              <ItemBox
                direction="row"
                width="100%"

                height="100%">
                <Item ratio={3}>
                  <div>

                    <ItemBox
                      direction="col"
                      height={"100px"}
                    >
                      <Item ratio={1} height={"25px"}>
                      <ItemBox
                          direction="row"
                         // width={"250px"}
                        >
                          <Item ratio={5} >

                            <div style={{ textAlign: "center", paddingTop: "5px", height: "25px" }}>
                            <b>{title}</b>

                            </div>
                          </Item>
                          <Item ratio={1} >

                            <div style={{ textAlign: "right", paddingTop: "5px", height: "25px" }}>
                          <b>{zeiten.length}</b>
                              
                            </div>
                          </Item>
                          


                        </ItemBox>
                       
                      </Item>
                      <Item ratio={1} >
                        <div>

                          <Button text="Start" type="default" stylingMode="outlined" width={"100%"} onClick={function (e) {
                            var time = new Date().getTime();
                            setmain_time_start(time);
                            setmain_time("00:00,0")
                            setrest_time("")
                          }} />
                        </div>
                      </Item>
                      <Item ratio={1} height={"30px"}>
                        <ItemBox
                          direction="row"
                          width={"250px"}
                        >
                          <Item ratio={1} >

                            <div style={{ textAlign: "left", paddingTop: "10px", height: "40px" }}>
                              <b>{main_time}</b>
                            </div>
                          </Item>
                          <Item ratio={1} >

                            <div style={{ textAlign: "left", paddingTop: "10px", height: "40px" }}>
                              {rest_time}  
                            </div>
                          </Item>
                          <Item ratio={1} >

                            <div style={{ textAlign: "left", fontSize: "9px", paddingTop: "10px", height: "40px" }}>
                              {calc_times.avg_text} <br></br>  {calc_times.max_text} <br></br>  {calc_times.min_text}
                            </div>
                          </Item>


                        </ItemBox>


                      </Item>

                    </ItemBox>




                  </div>
                </Item>
                <Item ratio={1}>

                  <div>
                    <List height={"110px"} width={"100%"} dataSource={zeiten} displayExpr={"zeit"} itemDeleteMode={"context"} allowItemDeleting={true}
                    onItemDeleted={function(e){
                      localStorage.setItem("zeiten;" + testID + ";" + sportlerID, JSON.stringify(zeiten))
                      calc_times_berechnen(zeiten)
                    }}>

                    </List>
                  </div>
                </Item>

              </ItemBox>
            </div>
          </Item>
          <Item ratio={1}>
            <ItemBox
              direction="row"
              width={"300px"}
            >
              <Item ratio={1} >

                <div>
                  <Button text="Zeit" type="success" stylingMode="outlined" width={"100%"} onClick={function (e) {
                    var time = new Date().getTime();
                    zwischenzeit(time);
                  }}>

                  </Button>
                </div>
              </Item>
              <Item ratio={1} visible={isTimePuffer} >

                <Button type="success" text="Zeit-Speicher" width={"100%"} onClick={function (e) {
               
                  zwischenzeit(timePuffer[0]);
                  onTimePufferChanged(timePuffer.slice(1));
                }}>

                </Button>
              </Item>

            </ItemBox>
            <div>


            </div>
          </Item>

        </ItemBox>













      </div>
    </div>
  )
})
