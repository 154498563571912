import { withNavigationWatcher } from './contexts/navigation';
import * as pages from './pages';

const routes = [
  {
    path: '/profile',
    component: pages.ProfilePage
  },
  {
    path: '/home',
    component: pages.HomePage
  }, 
  {
    path: '/wettkampf-notizen/:id',
    component: pages.WettkampfNotizenPage
  }, 
  {
    path: '/wettkampf-notizen-auswertung/:id',
    component: pages.WettkampfNotizenAuswertungPage
  }, 
  {
    path: '/wettkampf-notizen-veranstaltung',
    component: pages.WettkampfNotizenVeranstaltungPage
  },
  {
    path: '/wettkampf-veranstaltungen',
    component: pages.WettkampfErgebnisVeranstaltungPage
  }
  ,
  {
    path: '/wettkampf-wettkaempfe/:id',
    component: pages.WettkampfErgebnisWettkaempfePage
  }
  ,
  {
    path: '/wettkampf-ergebnisse/:id',
    component: pages.WettkampfErgebnisErgebnissePage
  },
  {
    path: '/wettkampf-uebersicht/:id',
    component: pages.WettkampfErgebnisUebersichtPage
  },
  {
    path: '/test/stopwatch/:testID',
    component: pages.TestStopwatchPage
  },
  {
    path: '/test/zeiten/:testID',
    component: pages.TestZeitenPage
  },
  {
    path: '/test/uebersicht',
    component: pages.TestUebersichtPage
  },
  {
    path: '/monitor/uebersicht',
    component: pages.MonitorPage
  },
  {
    path: '/wasser/vorlagen/anzeigen',
    component: pages.WasserVorlageListPage
  },
  {
    path: '/wasser/vorlagen/bearbeiten/:id',
    component: pages.WasserVorlageEditPage
  }
];

export default routes.map(route => {
  return {
    ...route,
    component: withNavigationWatcher(route.component)
  };
});
