export function getDataStore(table, key_value) {
    return {
        //  data: tasks,

        type: 'odata',
        url: 'https://odatalspdresdenv2.csdresden.de/ODataLSPDresden.svc/lspdresden_' + table,
        key: key_value,

        withCredentials: true,
        beforeSend: function (request) {
            request.headers = {
                "Authorization": 'Basic lspdresden:#passlspdresden#'
            };
            if (request.method == 'MERGE' || request.method == 'DELETE') {
                request.headers['X-HTTP-Method'] = request.method;
                request.method = 'POST';
            }
        },
    }
  }