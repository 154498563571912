import React from 'react';
import 'devextreme/data/odata/store';
import { useHistory } from "react-router-dom";
import DataGrid, {
    Button,
    Column,
    Editing,
    Lookup,
    MasterDetail
} from 'devextreme-react/data-grid';
import { getDataStore } from '../../../api/datastore';
import Guid from 'devextreme/core/guid';
import { useParams } from 'react-router-dom';


export default function Task() {

    const history = useHistory();
    let { id } = useParams();
    const dataSource = {
        store: getDataStore('wkergebnis', 'wkergebnis_id'), filter: ['wkwettkampf_id', "=", new Guid(id)]


    };
    const sportlerIDs = {
        store: getDataStore('sportler','sportlerID')
            ,filter:[["gesperrt","=", 0],["trainer","=", 0]]
          
          };

    return (
        <React.Fragment>
            <h2 className={'content-block'}>Wettkampf-Ergebnisse</h2>

            <DataGrid
                className={'dx-card wide-card'}
                dataSource={dataSource}
                showBorders={false}

                columnAutoWidth={true}

            >
                <Editing
                    mode="cell"
                    allowUpdating={true}
                    allowAdding={true}
                    allowDeleting={true} />

                <Column
                    dataField={'sportlerID'}
                    caption={'Sportler'}

                >
                    <Lookup
                        dataSource={sportlerIDs}
                        valueExpr={'sportlerID'}
                        displayExpr={function (e) { return (e.vorname + " " + e.nachname) }}
                    />

                </Column>
                <Column
                    dataField={'endzeit'}
                    caption={'Endzeit'}

                />
                <Column
                    dataField={'endzeit_ms'}
                    caption={'Endzeit in MS'}

                />
                <Column
                    dataField={'platz'}
                    caption={'Platz'}

                >


                </Column>
                <Column
                    dataField={'bemerkung'}
                    caption={'Bemerkung'}

                />







            </DataGrid>
        </React.Fragment>
    )
}



